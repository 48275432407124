<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <h3>
            Create Property Type
          </h3>
          <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>
          <CInput label="Name" type="text" placeholder="Name" v-model="propertyType.name"></CInput>
          <CButton color="primary" @click="store()">Create</CButton>
          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
//import axios from 'axios'
export default {
  name: 'CreatePropertyType',
  /*
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  */
  data: () => {
    return {
      propertyType: {
        name: ''
      },
      message: '',
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    store() {
      let self = this
      //axios.post(  this.$apiAdress + 'propertyTypes?token=' + localStorage.getItem("api_token"),
      this.$http
        .post('admin/property_types', { name: self.propertyType.name })
        .then(() => {
          self.message = 'Successfully created property type.'
          self.showAlert()
        })
        .catch(error => {
          if (error.response.data.message == 'The given data was invalid.') {
            self.message = ''
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
            self.showAlert()
          } else {
            console.error(error)
            //self.$router.push({ path: 'login' })
          }
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    let self = this
    //axios.get(  this.$apiAdress + 'propertyTypes/create?token=' + localStorage.getItem("api_token"))
    this.$http
      .get('admin/property_types/create')
      .then(response => {
        self.statuses = response.data
      })
      .catch(error => {
        console.error(error)
        self.$router.push({ path: 'login' })
      })
  }
}
</script>
